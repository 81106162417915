<template>
    <div class="experience">
        <img src="@/assets/img/experience_1.jpg" alt="椎茸" class="experience--bigimg">
        <div class="experience--about">
            <h1>農業体験</h1>
            <fade-in-component>
                <div class="experience--about--p">
                    <p>SORAのおいしさの秘訣を知る。</p>
                    <p>とって、たべて、かんじる。</p>
                </div>
            </fade-in-component>
        </div>
        <div class="experience--item">
            <div class="experience--item--subtitle">
                <img src="@/assets/img/border_left.jpg" alt="筆線左">
                <h1>収穫体験</h1>
                <img src="@/assets/img/border_right.jpg" alt="筆線右">
            </div>
            <div class="experience--item--contents">
                <div class="experience--item--contents--img">
                    <img src="@/assets/img/experience_2.jpg" alt="梨狩り風景">
                </div>
                <div class="experience--item--contents--right">
                    <!-- <h2>1人&nbsp;1000円<span>(税込)</span>/&nbsp;~~付き</h2> -->
                    <p>
                        <!-- スタッフが説明しながら<br class="pc-none">収穫体験を行っていただきます。<br>唐津の自然を体感しましょう!<br><br> -->
                        8月より開始。詳細は準備中。
                    </p>
                </div>
            </div>
        </div>
        <div class="experience--item">
            <div class="experience--item--subtitle">
                <img src="@/assets/img/border_left.jpg" alt="筆線左">
                <h1>梨狩り</h1>
                <img src="@/assets/img/border_right.jpg" alt="筆線右">
            </div>
            <div class="experience--item--contents">
                <div class="experience--item--contents--img">                    
                    <img src="@/assets/img/experience_3.jpg" alt="梨狩り風景">
                </div>
                <div class="experience--item--contents--right">
                    <!-- <h2>1人&nbsp;1000円<span>(税込)</span>/&nbsp;~~付き</h2> -->
                    <p>8月より開始。詳細は準備中。</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FadeInComponent from '../components/FadeInComponent.vue'

export default{
  components: { FadeInComponent }
}
</script>

<style lang="scss">
.experience{
    margin: 0em;
    padding: 0em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ten-mincho,"貂明朝", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; 

    a{
        text-decoration: none;
        color: black;
    }

    &--bigimg{
        width: 100%;
    }

    &--about{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5em auto 12em auto;

        &--p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        h1{
            font-size: 1.6em;
            font-weight: 100;
            margin-bottom: 3em;
        }

        p{
            font-size: 1.2em;
            line-height: 2em;
        }
    }

    &--item{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &--subtitle{
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 30%;
                height: 0.5em;
            }

            h1{
                font-weight: 100;
                font-size: 1.5em;
                margin: 0em 2.5em;
            }
        }

        &--contents{
            display: flex;
            margin: 6em 0em 9em 0em;
            justify-content: center;

            img{
                width: 20em;
                margin-right: 3em;
            }

            &--right{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 30em;
                font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;
                margin-top: 1em;

                h2{
                    font-size: 1.5em;
                    font-weight: 100;
                }

                p{
                    line-height: 2em;
                    margin-top: 1em;
                }
            }
        }
    }
}

@media screen and (max-width:800px) {
  .cellphone-none{
    display: none;
  }

  .pc-none{
    display: block;
  }

  .experience{
    p{
      font-size: 1em;
      line-height: 1.8em;
      width: auto;
    }

    h1{
      font-size: 1.2em;
      line-height: 1.7em;
    }

    &--about{
        margin: 3em auto 6em auto;
    }

    &--item{
        &--subtitle{
            h1{
                margin: 0 1em;
            }

            img{
                height: 0.2em;
            }
        }

        &--contents{
            flex-direction: column;
            align-items: center;
            margin: 3em auto 6em auto;

            img{
                width: 15em;
                margin: 0;
            }

            &--right{
                width: 100%;
                margin-top: 3em;

                h2{
                    font-size: 1.1em;
                }

                p{
                    font-size: 0.9em;
                    text-align: center;
                }
            }
        }
    }
  }
}
</style>