<template>
    <div class="members">
        <img src="@/assets/img/members_1.jpg" alt="風景画像" class="members--bigimg">
        <h1 class="pagetitle">生産者のご紹介</h1>
        <div class="members--wrapper">
            <div class="members--wrapper--item">
                <img src="@/assets/img/member_1.jpg" alt="生産者様の画像" class="members--wrapper--item--img" v-on:click="activeModal=1">
                <p class="members--wrapper--item--name">川添 サヨミさん</p>
                <p class="members--wrapper--item--crops">#パクチー<br>#ネギ<br>#じゃがいも</p>
            </div>
            <div class="members--wrapper--item">
                <img src="@/assets/img/member_2.jpg" alt="生産者様の画像" class="members--wrapper--item--img" v-on:click="activeModal=2">
                <p class="members--wrapper--item--name">岩崎 ヒデコさん</p>
                <p class="members--wrapper--item--crops">#ゴーヤ<br>#しいたけ<br>#ネギ</p>
            </div>
            <div class="members--wrapper--item">
                <img src="@/assets/img/member_3.jpg" alt="生産者様の画像" class="members--wrapper--item--img" v-on:click="activeModal=3">
                <p class="members--wrapper--item--name">川添 さん</p>
                <p class="members--wrapper--item--crops"></p>
            </div>
        </div>
        <transition name="members-modal">
            <div class="overlay"  v-show="activeModal==1" v-on:click="closeModal">
                <div class="modal" id="modal-1" v-on:click="stopEvent">
                    <button v-on:click="closeModal" class="modal--closebutton">&times;&nbsp;閉じる</button>
                    <div class="modal--contents">
                        <div class="modal--left">
                            <img src="@/assets/img/members_modal_1.jpg" alt="生産者様の画像">
                            <p class="modal--name">川添　サヨミさん</p>
                        </div>
                            <div class="modal--back pc-none"></div>
                        <div class="modal--sentenses">
                            <p class="modal--sentenses--message">人のいらすけん、よか</p>
                            <p class="modal--sentenses--about">
                            寝ている時も野菜のことを考えている<br class="pc-none">川添さん。<br class="cellphone-none">
                            元々梨農家として30年間<br class="pc-none">働いていたが、<br class="cellphone-none">
                            現在1人で少量多品目の<br class="pc-none">野菜を生産している。<br class="cellphone-none">
                            生産を通じて、<br class="pc-none">コミュニケーションを取れることが<br>最大の喜びだと語る。</p>
                        </div>
                    </div>
                </div>                
            </div>
        </transition>
        <transition name="members-modal">
            <div class="overlay"  v-show="activeModal==2" v-on:click="closeModal">
                <div class="modal" id="modal-2" v-on:click="stopEvent">
                    <button v-on:click="closeModal" class="modal--closebutton">&times;&nbsp;閉じる</button>
                    <div class="modal--contents">
                        <div class="modal--left">
                            <img src="@/assets/img/members_modal_2.jpg" alt="生産者様の画像">
                            <p class="modal--name">岩崎 ヒデコさん</p>
                        </div>
                            <div class="modal--back pc-none"></div>
                        <div class="modal--sentenses">
                            <p class="modal--sentenses--message">楽しみでね、色々植えてみよらすとよ。</p>
                            <p class="modal--sentenses--about">
                            様々な野菜を生産している岩崎さん。<br>
                            農薬を極力使わずに、野菜の苗を<br>
                            とことん栽培することを大切にしています。<br>
                            野菜を通じて、近隣の方とお話することが<br>毎日の楽しみです。雨が降って<br>畑に出られないとウズウズして仕方ないです。</p>
                        </div>
                    </div>
                </div>                
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeModal: 0
        }
    },
    methods: {
        closeModal: function(){
            this.activeModal = 0
        },
        stopEvent: function(){
            event.stopPropagation()
        }
    }
}
</script>

<style lang="scss">
.members{
    margin: 0em;
    padding: 0em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ten-mincho,"貂明朝", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; 

    a{
        text-decoration: none;
        color: black;
    }

    p{
        margin: 0em;
        padding:0em;
        font-family: sans-serif;
    }

    h1{
        font-size: 1.6em;
        font-weight: 100;
    }

    &--bigimg{
        width: 100%;
    }

    .pagetitle{
        margin: 6em auto 6em auto;
    }

    &--wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;

        &--item{
            width: 15em;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0em 1.5em 5em 1.5em;

            &--img{
                width: 100%;
                &:hover{
                    cursor: pointer;
                }
            }

            p{
                margin: 1em auto 1.5em auto;
            }

            &--crops{
                text-align: center;
            }
        }
    }
}

// Styles for modals
.overlay{
    z-index: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50,50,50,0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal{
        z-index: 2;
        width: 48em;
        height: 28em;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 5em;

        &--contents{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 1em;

            img{
                width: 16em;
            }

            p{
                font-size: 1.3em;
                margin-top: 1em;
            }
        }

        &--closebutton{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 0;
            border-radius: 0;
            background: rgba(0,0,0,0);
            margin: 1em 3em 2em auto;
            font-size: 1.15em;

            &:hover{
                cursor: pointer;
            }
        }

        &--sentenses{
            text-align: center;
            display: flex;
            flex-direction: column;
            margin: 0em 1em 0em auto;
            justify-content:  center;
            padding: 2em 1em;

            &--message{
                font-size: 1.5em;
                margin-bottom: 1em;
            }

            &--about{
                line-height: 1.7em;
            }

            &--name{
                font-size: 1.5em;
                margin-top: 0.8em;
            }
        }
    }
}

// transition
.members-modal-enter-active, .members-modal-leave-active {
    transition: opacity .3s ease;
}
.members-modal-enter, .members-modal-leave-to {
    opacity: 0;
}

@media screen and (max-width:800px) {
  .cellphone-none{
    display: none;
  }

  .pc-none{
    display: block;
  }

  .members{
    p{
      font-size: 1em;
      line-height: 1.8em;
    }

    h1{
      font-size: 1.2em;
      line-height: 1.7em;
    }

    .pagetitle{
        margin: 3em auto;
    }

    &--wrapper{

        &--item{
            width: 12em;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0em 1.5em 3em 1.5em;

            &--img{
                width: 100%;
                &:hover{
                    cursor: pointer;
                }
            }

            p{
                margin: 0.5em auto 0.5em auto;
            }

            &--crops{
                text-align: center;
            }
        }
    }
  }
 .overlay{

    .modal{
        width: 22em;
        height: 26em;
        flex-direction: column;
        border-radius: 1.5em;
        position: relative;

        &--contents{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &--left{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 0em;
            width: 100%;
            height: 9em;
            z-index: 5;
            background-color: rgba(0,0,0,0);

            img{
                width: 9em;
                margin-bottom: auto;
            }

            p{
                font-size: 1.1em;
                margin-top: auto;
                margin-bottom: 1em;
            }
        }

        &--closebutton{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 0;
            border-radius: 0;
            background: rgba(0,0,0,0);
            margin: 0.5em 1.5em 0.5em auto;
            font-size: 1em;

            &:hover{
                cursor: pointer;
            }
        }

        &--sentenses{
            margin: 0em;
            padding: 0;

            &--message{
                font-size: 1.1em;
                margin: 1em auto 0.5em auto;
            }

            &--about{
                font-size: 0.85em;
                line-height: 1.7em;
            }
        }
    }

 }
    .modal--back{
        z-index: 4;
        position: absolute;
        width: 22em;
        height: 3em;
        top: 8em;
        left: 0;
        background-color: #D2E2C2;
        
    }
}
</style>