import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Experience from '../views/Experience.vue'
import Shops from '../views/Shops.vue'
import Members from '../views/Members.vue'
import Access from '../views/Access.vue'
import Contact from '../views/Contact.vue'
import Company from '../views/Company.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/experience',
    name: 'Experience',
    component: Experience
  },
  {
    path: '/shops',
    name: 'Shops',
    component: Shops
  },
  {
    path: '/members',
    name: 'Members',
    component: Members
  },
  {
    path: '/access',
    name: 'Access',
    component: Access
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/company',
    name: 'Company',
    component: Company
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: async (to, from, savedPosition) => {
      if (savedPosition) {
          return savedPosition;
      }

      const findEl = async (hash, x) => {
          return document.querySelector(hash) ||
              new Promise((resolve, reject) => {
                  if (x > 50) {
                      return resolve();
                  }
                  setTimeout(() => {
                      resolve(findEl(hash, ++x || 1));
                  }, 100);
              });
      }

      if (to.hash) {
          const el = await findEl(to.hash);
          return window.scrollTo({top: el.offsetTop, behavior: 'smooth'});
      }

      return {x: 0, y: 0};
  }
})

export default router
