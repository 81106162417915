<template>
    <div class="contact">
        <a @click="$router.back()" class="contact--back">戻る</a>
        <div class="contact--subtitle"><p>SORAお問い合わせフォーム</p></div>
        <div class="formArea" id="postForm">
            <form class="form" v-on:submit.prevent="submit" v-if="showForm">
                <div class="formGroup">
                    <label class="formLabel" for="userName">お名前</label>
                    <input class="formInput" id="userName" type="text" v-model="userName" required>
                </div>
                <div class="formGroup">
                    <label class="formLabel" for="email">メールアドレス</label>
                    <input class="formInput" id="email" type="text" v-model="email" required>
                </div>
                <div class="formGroup">
                    <label class="formLabel" for="content">内容</label>
                    <textarea class="formInput" id="content" v-model="content" required></textarea>
                </div>
                <div class="formGroup">                    
                    <input class="formBtn" type="submit" value="送信する">
                </div>
            </form>
            <div class="confirm" v-else>
                <p class="confirmMessage">
                    お問い合わせいただきありがとうございます!<br>
                    以下の内容で受け付けました。
                </p>
                <div class="confirmGroup">                    
                    <p class="confirmLabel">お名前：</p>
                    <p class="confirmContent">{{userName}}</p>
                </div>
                <div class="confirmGroup">                    
                    <p class="confirmLabel">メールアドレス：</p>
                    <p class="confirmContent">{{email}}</p>
                </div>
                <div class="confirmGroup">                    
                    <p class="confirmLabel">内容：</p>                       
                        <p class="confirmContent">{{content}}</p>
                </div>
                <a href="/" class="confirmBtn">TOP</a>
            </div>
        </div>
    </div>
</template>

<!-- jquery -->
<script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
<!-- ajax -->
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>

<script>
import axios from 'axios';

export default {
  data(){
    return {
        // フォームの表示
        showForm: true,
        // お名前
        userName: "",
        // メールアドレス
        email: "",
        // ご依頼内容
        content: ""
    }
  },
  methods: {
    submit: function () {
        const submitParams = new FormData();
        // お名前のname属性値
        submitParams.append("entry.1763302054", this.userName);
        // メールアドレスのname属性値
        submitParams.append("entry.2135491667", this.email);
        // ご依頼内容のname属性値
        submitParams.append("entry.904717654", this.content);

        // CORSエラー対策
        // const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";
        const CORS_PROXY = "";
        // Googleフォームのaction属性値
        const GOOGLE_FORM_ACTION = "https://docs.google.com/forms/u/1/d/e/1FAIpQLSdqqanM67uYnVck9nwZOjDQTEqF2qDW9CtZ6TII1_hfm-Q4lA/formResponse";

        // Ajax POST通信
        axios.post(CORS_PROXY + GOOGLE_FORM_ACTION, submitParams).then(res => {
            // フォーム非表示
            this.showForm = false;
        }).catch(function (error) {
            if (error.response) {
            // 要求がなされたとサーバがステータスコードで応答した
            // 2XXの範囲外
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
            // 要求がなされたが、応答が受信されなかった
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            } else {
            // トリガーしたリクエストの設定に何かしらのエラーがある
            console.log('Error', error.message);
            }
            console.log(error.config);
        });
        // フォーム非表示
        this.showForm = false;
    }
  }
}
</script>

<style lang="scss">
.contact{
    margin: 0em;
    padding: 0em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ten-mincho,"貂明朝", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; 

    a{
        text-decoration: none;
        color: black;
        font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;
    }

    p{
        margin: 0em;
        padding:0em;
        font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;
    }

    h1{
        font-size: 1.6em;
        font-weight: 100;
    }

    &--back{
        margin: 6em 2em 0em auto;
        border: solid;
        border-width: 0.1em;
        border-color: #aaa;
        border-radius: 1em;
        padding: 0.25em 1em; 
        font-size: 1em;

        &:hover{
            cursor: pointer;
        }
    }

    &--subtitle{
        width: 90%;
        font-size: 1em;
        background-color: #ddd;
        display: flex;
        height: 2.5em;
        align-items: center;
        justify-content: center;
        margin-top: 3em;
    }

    // style form forms
    .formArea{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2em;
    }

    .formGroup{
        display: flex;
        width: 48em;
        margin-top: 2.5em;

        .formLabel{
            font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;
            font-size: 1.2em;
            width: 7em;
            text-align: right;
        }

        .formInput{
            margin-left: auto;
            width: 27em;
            margin-right: 7em;
            border: solid;
            border-color: #666;
            border-radius: 1em;
            border-width: 0.1em;
            font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;
        }

        textarea{
            height: 10em;
        }

        .formBtn{
            margin: 0em auto 0em auto;
            border: solid;
            border-width: 0.1em;
            border-color: #aaa;
            border-radius: 1em;
            padding: 0.25em 1em; 
            background-color: #fff;
            font-size: 1em;
            font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;

            &:hover{
                cursor: pointer;
            }
        }
    }

    //form送信後の確認画面
    .confirm{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2em;

        .confirmMessage{
            text-align: center;
        }

        .confirmGroup{
            display: flex;
            width: 37em;
            margin-top: 2.5em;
        }

        .confirmLabel{
            font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;
            width: 9em;
            text-align: right;
        }

        .confirmContent{
            margin-left: auto;
            width: 25em;
            word-wrap: break-word;
            text-align: left;
            margin-right: auto;
            margin-left: 1em;
        }

        .confirmBtn{
            margin: 5em auto 0em auto;
            border: solid;
            border-color: #aaa;
            border-width: 0.1em;
            border-radius: 1em;
            padding: 0.25em 1em; 
            background-color: #fff;
            font-size: 1em;

            &:hover{
                cursor: pointer;
            }   
        }
    }
}

@media screen and (max-width:800px) {
  .cellphone-none{
    display: none;
  }

  .pc-none{
    display: block;
  }

  .contact{
    p{
      font-size: 1em;
      line-height: 1.8em;
    }

    h1{
      font-size: 1.2em;
      line-height: 1.7em;
    }

    &--subtitle{
        width: 100%;
        margin-top: 1em;
    }

    &--back{
        font-size: 0.9em;
        margin-top: 3em;
        margin-right: 1em;
    }

    .formGroup{
        width: 20em;
        margin-top: 1.5em;

        .formLabel{
            font-size: 0.8em;
            width: 7em;
            margin-left: 0.5em;
        }

        .formInput{
            margin-left: auto;
            width: 16.5em;
            margin-right: 0.5em;
            font-size: 0.8em;
        }

        textarea{
            height: 6em;
        }

        .formBtn{
            font-size: 0.9em;
        }
    }

    .confirm{
        font-size: 0.8em;
        .confirmMessage{
            font-size: 0.9em;
        }

        .confirmGroup{
            width: auto;
        }

        .confirmLabel{
            width: 8em;
        }

        .confirmContent{
            width: 15em;
        }
    }
  }
}
</style>