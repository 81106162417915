<template>
  <div class="home">
    <img src="@/assets/img/home_1.jpg" alt="風景画像1" class="home--bigimg">
    <fade-in-component>
      <div class="home--sentenses">
        <h1>農業を通し<br>地域の<ruby><rb>希望</rb><rt>ひかり</rt></ruby>になる</h1>
        <p>SORAは中山間地区での<br>少子高齢化・稲作放棄等の問題に<br>正面から取り組んでいます。</p>
        <p>農業・食の力で<br>子供から高齢者のすべての世代が、<br>笑顔で元気に暮らせる<br>地域づくりを目指します。</p>
      </div>
    </fade-in-component>
    <img src="@/assets/img/home_2.jpg" alt="風景画像2" class="home--bigimg">
    <fade-in-component>
      <div class="home--sentenses">
        <h1>事業内容</h1>
        <p>幸水梨・豊水梨・新高梨・愛宕梨<br>4種類の梨の生産から販売。<br>生産者の思いが詰まった野菜の流通。</p>
        <p>豊かな土地、唐津。<br>最大限の魅力を引き出し、<br>お客様に最良のものを届けたい。<br>そんな想いで事業活動しています。</p>
      </div>
    </fade-in-component>
    <img src="@/assets/img/home_3.jpg" alt="風景画像3" class="home--bigimg">
    <fade-in-component>
      <div class="home--sentenses">
        <h1>ごあいさつ</h1>
        <p>「もったいなか。」<br>経験が豊富で元気いっぱいの高齢者<br>関心がピカイチの若者<br>この地域には多種多様な世代が<br>光として活躍しています。</p>
        <p>何もないなんて土地はありません。<br>過疎・高齢化は深刻な状況ですが、<br>私たちは何ができるのかを問い続けます。</p>
      </div>
    </fade-in-component>
    <img src="@/assets/img/home_4.jpg" alt="風景画像2" class="home--bigimg">
    <div class="home--border cellphone-none"></div>
    <div id="soraco"></div> <!-- ページ遷移用 -->
    <div class="home--soraco">
      <a href="https://soraco.shop/" class="home--soraco--left">
        <img src="@/assets/img/home_soraco.jpg" alt="ソラコの画面画像">
        <p>クリックするとソラコHPへ移行します</p>
      </a>
        <div class="home--soraco--sentenses">
          <!-- <fade-in-component> -->
              <span>
                <h1>ソラコ</h1>
                <p>食べる人、つくる人、<br class="pc-none">みんなを笑顔にしたい<br>という想いで産直販売を<br class="pc-none">行なっております。</p>
                <p>関わる全ての世代を笑顔にさせる<br>ワクワクする八百屋さんです。</p>
              </span>
          <!-- </fade-in-component> -->
        </div>
    </div>
  </div>
</template>

<script>
import FadeInComponent from '../components/FadeInComponent.vue'

  export default {
    name: 'Home',
    components:{
        FadeInComponent
    }
  }
</script>

<style lang="scss">
.pc-none{
  display: none;
}

.home{
  margin: 0em;
  padding: 0em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ten-mincho,"貂明朝", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; 

  a{
      text-decoration: none;
      color: black;
  }
  .vertical{
      writing-mode: vertical-rl;
      text-orientation: upright;
  }

  &--bigimg{
    width: 100%;
  }

  &--sentenses{
    writing-mode: vertical-rl;
    text-orientation: upright;
    height: 29em;
    margin: 4em auto 4em auto;
    width: 20em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1{
      font-size: 1.5em;
      font-weight: lighter;
      letter-spacing: 0.5em;
      line-height: 2.4em;       
    }

    p{
      height: 29em;
      margin: auto 4em 0em 0em;
      font-size: 1em;
      letter-spacing: 0.5em;
      line-height: 2.6em;
    }
  }

  &--border{
    width: 90%;
    margin-top: 6em;
    margin-bottom: 3em;
    border: solid;
    border-top: 1px;
    border-color: #ccc;
  }

  #soraco{
    height: 3em;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &--soraco{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .hide{
      width: auto;
    }

    &--sentenses{
      height: 30em;
      writing-mode: vertical-rl;
      text-orientation: upright;
      display: block;
      margin-right: auto;
      margin-left: 3em;

      h1{
        font-size: 1.5em;
        font-weight: lighter;
        letter-spacing: 0.5em;
        line-height: 2.4em;
      }

      p{
        height: 30em;
        margin: auto 2em 0em 0em;
        font-size: 1em;
        letter-spacing: 0.5em;
        line-height: 2em;
      }
    }

    &--left{
      width: 20em;
      height: 100%;
      margin-right: 3em;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p{
        font-family: 'toppan-bunkyu-gothic-pr6n',sans-serif;
        font-size: 1em;
        letter-spacing: 0em;
        margin: 0em;
        padding: 0em;
        text-align: center;
      }
      img{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width:800px) {
  .cellphone-none{
    display: none;
  }

  .pc-none{
    display: block;
  }

  .home{
    p{
      font-size: 1em;
      line-height: 1.8em;
    }

    h1{
      font-size: 1.2em;
      line-height: 1.7em;
    }

    &--sentenses{
      height: auto;

      p{
        margin: 1em 0.6em 0 0.6em;
      }
    }

    &--soraco{
      flex-direction: column-reverse;
      margin-top: 2em;

      &--sentenses{
        height: 25em;
        margin: 0 auto;
        display: flex;
        align-items: center;
        
        p{
          margin: 1em 0.5em 0 0.5em;
        }
      }

      &--left{
        margin: 0;
        margin-top: 3em;

        img{
          width: 80%;
        }

        p{
          color: blue;
          font-size: 0.8em;          
        }
      }
    }
  }
}
</style>