<template>
    <div class="header" id="header">
        <router-link to="/">
        <img src="@/assets/img/logo.jpg" alt="ロゴ画像" class="header--logo">
        </router-link>
        <ul class="header--nav vertical">
            <li id="sora"><router-link to="/">SORA</router-link></li>
            <li><router-link to="/experience">農業体験</router-link></li>
            <li><router-link to="/shops">事業内容</router-link></li>
            <li id="members"><router-link to="/members">生産者のご紹介</router-link></li>
            <li><router-link to="/company">会社概要</router-link></li>
            <li><router-link to="/access">アクセス</router-link></li>
            <li><router-link to="/access">お問い合わせ</router-link></li>
            <li><router-link to="/#soraco">ソラコについて</router-link>
            </li>
        </ul>          
    </div>
</template>

<script>
    (function(d) {
        var config = {
        kitId: 'xnk0tbd',
        scriptTimeout: 3000,
        async: true
        },
        h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
    })(document);
</script>

<style lang="scss">

    .header {
        margin: 0em 0em 2em 0em;
        padding: 0em;
        font-family: ten-mincho,"貂明朝", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; 
        height: 12.8em;
        display: flex;
        color: #333;

        a{
            text-decoration: none;
            color: #333;
        }

        .vertical{
            writing-mode: vertical-rl;
            text-orientation: upright;            
        }

        &--nav{
            font-size: 1em;
            letter-spacing: 0.5em;
            margin: 1em 3em auto auto;
            padding: 0em;        

            li{
                margin-right: 1.5em;
                list-style: none;
                color: #333;
            }            
        }

        &--logo{
            width: 14.5em;
            height: 5em;
            margin: 1.5em auto auto 1em;
        }
        
        #sora{
            margin-left: 2.5em;
            margin-right: 0em;
        }
        #members{
            margin-left: 3em;
        }
    }
</style>