<template>
  <div id="app">
    <header>
      <global-header-for-cellphone v-if="devise==0"/>
      <global-header-for-pc v-if="devise==1"/>
    </header>
    <div class="wrapper">
      <router-view/>
    </div>
    <footer>
      <global-footer-for-cellphone v-if="devise==0"/>
      <global-footer-for-pc v-if="devise==1"/>
    </footer>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
<!-- ajax -->
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>

<script>
  import GlobalHeaderForPc from './components/GlobalHeaderForPc'
  import GlobalFooterForPc from './components/GlobalFooterForPc'
  import GlobalHeaderForCellphone from './components/GlobalHeaderForCellphone'
  import GlobalFooterForCellphone from './components/GlobalFooterForCellphone'

  export default {
    data() {
      return {
          devise:1 // 画面サイズ 1:PC, 0:cellphone
      }
    },
    methods: {
      handleResize: function() {
        if (window.innerWidth <= 800) {
          this.devise = 0
        } else {
          this.devise = 1
        }
      }
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
      },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    components: {
      GlobalHeaderForPc,
      GlobalFooterForPc,
      GlobalHeaderForCellphone,
      GlobalFooterForCellphone
    },
    head: {
      title() {
        return {
          inner: '株式会社SORA｜唐津市から農業を通し、地域の希望の「光」になる。',
          separator: ' ',
          complement: ' '
        }
      },
      meta: [
        {name: 'description', content: 'SORAは農業・食の力で子供から高齢者のすべての世代が、笑顔で元気に暮らせる地域づくりを唐津から目指します。', id: 'desc'},
        {property: 'og:title', content: '株式会社SORA｜唐津市から農業を通し、地域の希望の「光」になる。'},
        {property: 'og:description', content: 'SORAは農業・食の力で子供から高齢者のすべての世代が、笑顔で元気に暮らせる地域づくりを唐津から目指します'},
        {property: 'og:url', content: 'https://sora-cdb03.web.app/'},
        {property: 'og:image', content: 'https://soranokai.club/img/company_1.7f29a8a6.jpg'},
        {property: 'og:type', content: 'website'},

        // Twitter
        {name: 'twitter:title', content: '株式会社SORA｜唐津市から農業を通し、地域の希望の「光」になる。'},
        {name: 'twitter:description', content: 'SORAは農業・食の力で子供から高齢者のすべての世代が、笑顔で元気に暮らせる地域づくりを唐津から目指します。'},
        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:image:src', content: 'https://soranokai.club/img/company_1.7f29a8a6.jpg'}
      ]
    }
  }
</script>

<style lang="scss">
  body {
    padding: 0em;
    margin: 0em;
  }

  #app{
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding-bottom: 45em; //footerのheightと合わせる
    box-sizing: border-box;
  }

  .wrapper{
    margin: 0em 3em;
    color: #444;
  }

  //fadein
  .contentsShow {
    opacity: 1;
    animation: contentsFadeIn 1s;
  }
  @keyframes contentsFadeIn {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }


@media screen and (max-width:800px) { 
  #app{
    padding-bottom: 17em;
  }

  .wrapper{
    padding-top: 60px;
    width: 100%;
    margin: 0;
  }
}
</style>
