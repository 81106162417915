<template>
    <div class="header-for-cellphone">
        <div class="header-for-cellphone--head">
            <div class="hamburger_btn" v-on:click='ActiveBtn=!ActiveBtn'>
                <span class="line line_01" v-bind:class="{'btn_line01':ActiveBtn}"></span>
                <span class="line line_02" v-bind:class="{'btn_line02':ActiveBtn}"></span>
                <span class="line line_03" v-bind:class="{'btn_line03':ActiveBtn}"></span>
            </div>
            <a href="#">
                <img src="@/assets/img/logo.jpg" alt="ロゴ画像">
            </a>
        </div>
        <transition name="hamburger_menu">
            <div class="hamburger_menu" v-show="ActiveBtn">
                <ul class="hamburger_menu--nav">
                    <li v-on:click='ActiveBtn=!ActiveBtn'><router-link to="/">SORA</router-link></li>
                    <li v-on:click='ActiveBtn=!ActiveBtn'><router-link to="/experience">農業体験</router-link></li>
                    <li v-on:click='ActiveBtn=!ActiveBtn'><router-link to="/shops">事業内容</router-link></li>
                    <li v-on:click='ActiveBtn=!ActiveBtn'><router-link to="/members">生産者のご紹介</router-link></li>
                    <li v-on:click='ActiveBtn=!ActiveBtn'><router-link to="/company">会社概要</router-link></li>
                    <li v-on:click='ActiveBtn=!ActiveBtn'><router-link to="/access">アクセス</router-link></li>
                    <li v-on:click='ActiveBtn=!ActiveBtn'><router-link to="/access">お問い合わせ</router-link></li>
                    <li v-on:click='ActiveBtn=!ActiveBtn'><router-link to="/#soraco">ソラコについて</router-link>
                    </li>
                </ul>    
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data(){ return {
        ActiveBtn: false
    }}
}
</script>

<style lang="scss">
.header-for-cellphone{
    display: flex;
    flex-direction: column;
    align-items: center;

    &--head{
        height: 60px;
        width: 100%;
        position: fixed;
        z-index: 10;
        background-color: #fdfdfd;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img{
        width: 8em;
    }
}

.hamburger_btn {
    position: fixed; /*常に最上部に表示したいので固定*/
    top: 15px;
    left: 0;
    width: 55px;
    height: 35px;
    cursor: pointer;
    z-index: 50;

    .line {
        position: absolute;
        top: 12px;
        left: 20px;
        width: 24px;
        height: 2px;
        background: #333333;
        text-align: center;
    }

    .line_01 {
        top: 8px;
        transition: 0.4s ease;
    }
    .line_02 {
        top: 16px;
        transition: 0.4s ease;
    }
    .line_03 {
        top: 24px;
        transition: 0.4s ease;
    }

    .btn_line01 {
        transform: translateY(8px) rotate(-45deg);
        transition: 0.4s ease;
    }
    .btn_line02 {
        transition: 0.4s ease;
        opacity: 0;
    }
    .btn_line03 {
        transform: translateY(-8px) rotate(45deg);
        transition: 0.4s ease;
    }
}

.hamburger_menu{
    height:  80rem;
    width: 100%;
    z-index: 9;
    background-color: #fdfdfd;
    position: fixed;
    top: 60px;
    display: flex;
    
    ul{
        margin: 0 auto;
        line-height: 2em;
        width: 100%;

        li{
            list-style: none;
            width: 80%;
            border-bottom: solid .1em #333;
            margin: 0.5em auto;
            padding-right: auto;
            
            a{
                text-decoration: none;
                color: #333;
                font-family: ten-mincho,"貂明朝", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
                font-size: 0.9em;
                margin-left: 1em;
                display: inline-block;
                width: 100%;
            }
        }
    }
}

.hamburger_menu-enter-active, .hamburger_menu-leave-active {
  transition: opacity 0.4s;
}
.hamburger_menu-enter, .hamburger_menu-leave-to {
  opacity: 0;
}
.hamburger_menu-leave, .hamburger_menu-enter-to{
  opacity: 1;
}
</style>