<template>
    <div :class='[visible? "fadeIn" : "hidden"]'>
        <slot v-show="visible"></slot>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false
      };
    },
    created() {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("load", this.handleScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("load", this.handleScroll);
    },
    methods: {
      handleScroll() {
        if (!this.visible) {
          var top = this.$el.getBoundingClientRect().top;
          this.visible = top < window.innerHeight - 100;
        }
      }
    }
  }
</script>

<style scoped>
.hidden {
    opacity: 0;
    width: 100%;
}

.fadeIn {
    animation: fadeIn 2s;
    width: 100%;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(1.5em);
    }
    100% {
        opacity: 1;
        transform: translateY(0em);
    }
}
</style>